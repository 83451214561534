import api from '@/api/influencersPageApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    influencer_most_liked: [],
    influencer_most_followed: [],
    influencer_most_viewed: [],
    influencer_movers_shakers: [],
    influencer_profile_playlist: [],
    influencer_profile_categories: [],
    influencers_playlist: [],
    influencer: {},
    influencerInteraction: {}
  },

  getters: {
    getInfluencer (state) {
      return state.influencer
    },
    getInfluencerMostFollowed (state) {
      return state.influencer_most_followed
    },
    getInfluencerMostLiked (state) {
      return state.influencer_most_liked
    },
    getInfluencerInteraction (state) {
      return state.influencerInteraction
    },
    getInfluencerMostViewed (state) {
      return state.influencer_most_viewed
    },
    getInfluencerMoversAndShakers (state) {
      return state.influencer_movers_shakers
    },
    getInfluencersPlaylist (state) {
      return state.influencers_playlist
    },
    getInfluencersProfilePlaylist (state) {
      return state.influencer_profile_playlist
    },
    getInfluencersProfileCategories (state) {
      return state.influencer_profile_categories
    }

  },

  mutations: {
    setInfluencer (state, value) {
      state.influencer = value
    },
    setInfluencerMostLiked (state, value) {
      state.influencer_most_liked = value
    },
    setInfluencerMostFollowed (state, value) {
      state.influencer_most_followed = value
    },
    setInfluencerInteraction (state, value) {
      state.influencerInteraction = value
    },
    setInfluencerMostViewed (state, value) {
      state.influencer_most_viewed = value
    },
    setInfluencerMoversShakers (state, value) {
      state.influencer_movers_shakers = value
    },
    setInfluencersPlaylist (state, value) {
      state.influencers_playlist = value
    },
    setInfluencersProfilePlaylist (state, value) {
      state.influencer_profile_playlist = value
    },
    setInfluencersProfileCategories (state, value) {
      state.influencer_profile_categories = value
    },
    empty_Influencer_Feed (state, value) {
      state.influencer_profile_categories = value
    }
  },

  actions: {
    GET_INFLUENCER ({ commit, dispatch }, { params, success }) {
      api.influencerProfile(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencer', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_INFLUENCER_MOST_FOLLOWED ({ commit, dispatch }) {
      api.influencerMostFollowed(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerMostFollowed', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error))
    },
    GET_INFLUENCER_MOST_VIEWED ({ commit, dispatch }) {
      api.influencerMostViewed(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerMostViewed', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error))
    },
    GET_INFLUENCER_MOVERS_SHAKERS ({ commit, dispatch }) {
      api.influencerMoversAndShakers(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerMoversShakers', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error))
    },
    GET_INFLUENCER_LIKED ({ commit, dispatch }) {
      api.influencerMostLiked(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerMostLiked', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },
    PERFORM_INTERACTIONS ({ commit, dispatch }, { params }) {
      api.setInfluencerInteractions(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerInteraction', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_INTERACTIONS ({ commit, dispatch }, { params, success }) {
      api.getInfluencerInteractions(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencerInteraction', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_INFLUENCERS_PLAYLIST ({ commit, dispatch }, { params, success }) {
      api.influencersPlayList(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencersPlaylist', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_PROFILE_FEED_PLAYLIST ({ commit, dispatch }, { influencerId, success }) {
      api.influencerProfilePlaylist(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencersProfilePlaylist', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      influencerId
      )
    },
    GET_PROFILE_FEED_CATEGORIES ({ commit, dispatch }, { influencerId, success }) {
      api.influencerProfileCategories(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencersProfileCategories', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      influencerId
      )
    },
    CLEAR_CATEGORIES_FEED ({ commit, dispatch }, payload) {
      commit('empty_Influencer_Feed', payload)
    }

  }
}
