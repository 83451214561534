import Vue from 'vue'
import Vuex from 'vuex'

import authStore from './modules/authStore'
import homeStore from './modules/homeStore'
import myCoursesStore from './modules/myCoursesStore'
import analyticsStore from './modules/analyticsStore'
import influencersStore from './modules/influncersStore'
import messagesStore from '@/store/modules/messagesStore'
import notificationStore from '@/store/modules/notificationStore'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  modules: {
    authStore,
    homeStore,
    myCoursesStore,
    analyticsStore,
    influencersStore,
    messagesStore,
    notificationStore
  }
})
