import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import authUtils from '@/api/apiUtils/authUtils'
import { deserializeQueryParams } from '@/api/apiUtils/apiUtils'
import Constants from '../config/dev.json'

/* Layouts */
const Layout = () => import('../layouts/Layout')
const Default = () => import('../layouts/BlankLayout')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')

/* all routes */
const SocialProfile = () => import('../views/SocailMain/Profile/Profile')
const ProfileImage = () => import('../views/SocailMain/Profile/ProfileImage')
const ProfileVideo = () => import('../views/SocailMain/Profile/ProfileVideo')
const ProfileEvent = () => import('../views/SocailMain/Profile/ProfileEvent')
const ProfileBadges = () => import('../views/SocailMain/Profile/ProfileBadges')
const ProfileForum = () => import('../views/SocailMain/Profile/ProfileForum')
const FriendList = () => import('../views/SocailMain/Friends/FriendList')
const BlogGrid = () => import('../views/SocailMain/Blog/BlogGrid')
const BlogList = () => import('../views/SocailMain/Blog/BlogList')
const BlogDetail = () => import('../views/SocailMain/Blog/BlogDetail')
const CategoryGrid = () => import('../views/SocailMain/Store/CategoryGrid')
const CategoryList = () => import('../views/SocailMain/Store/CategoryList')
const StoreDetail = () => import('../views/SocailMain/Store/Storedetail')
const StoreCheckout = () => import('../views/SocailMain/Store/StoreCheckout-cart')
const StoreCheckoutaddress = () => import('../views/SocailMain/Store/StoreCheckout-address')
const StoreCheckoutpayment = () => import('../views/SocailMain/Store/StoreCheckout-payment')
const File = () => import('../views/SocailMain/File/File')
const Group = () => import('../views/SocailMain/Group/Group')
const Birthday = () => import('../views/SocailMain/Bithdate/Bithdate')
const Notification = () => import('../views/SocailMain/Notifications/Notification')
const Weather = () => import('../views/SocailMain/Weather/Weather')
const FriendRequest = () => import('../views/SocailMain/Friends/FriendRequest')
const Influencers = () => import('../views/Apps/Influencers/index.vue')
// const MyDeskDirectFromMenu = () => import('../views/SocailMain/MyDesk/VideoContentScreen')
const VideoContentScreen = () => import('../views/SocailMain/MyDesk/VideoContentScreen/VideoContentScreen.vue')
const MyCourses = () => import('../views/SocailMain/MyDesk/MyCourses/index.vue')
const InfluencerProfile = () => import('../views/Apps/InfluencerProfile/index.vue')
const Messages = () => import('../views/Messages/index')

/* UIElements View */
const UiAlerts = () => import('../views/core/UiAlerts.vue')
const UiButtons = () => import('../views/core/UiButtons.vue')
const UiBadges = () => import('../views/core/UiBadges.vue')
const UiBreadcrumb = () => import('../views/core/UiBreadcrumb.vue')
const UiColors = () => import('../views/core/UiColors.vue')
const UiTypography = () => import('../views/core/UiTypography.vue')
const UiCards = () => import('../views/core/UiCards.vue')
const UiCarousel = () => import('../views/core/UiCarousel.vue')
const UiEmbedVideo = () => import('../views/core/UiEmbedVideo.vue')
const UiGrid = () => import('../views/core/UiGrid.vue')
const UiModal = () => import('../views/core/UiModal.vue')
const UiListGroup = () => import('../views/core/UiListGroup.vue')
const UiImages = () => import('../views/core/UiImages.vue')
const UiMediaObject = () => import('../views/core/UiMediaObject.vue')
const UiTooltips = () => import('../views/core/UiTooltips.vue')
const UiPopovers = () => import('../views/core/UiPopovers.vue')
const UiNotifications = () => import('../views/core/UiNotifications.vue')
const UiTabs = () => import('../views/core/UiTabs.vue')
const UiPagination = () => import('../views/core/UiPagination.vue')
const UiProgressBars = () => import('../views/core/UiProgressBars.vue')
/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/SignIn/SignIn.vue')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('../views/AuthPages/Default/ConfirmMail1')
const ResetPassword = () => import('../views/AuthPages/Default/ResetPassword')
const EmailVerification = () => import('../views/AuthPages/Default/EmailVerification')
/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')
const ComingSoon = () => import('../views/Pages/ComingSoon')
const Maintenance = () => import('../views/Pages/Maintenance')
const TimeLine = () => import('../views/Pages/TimeLines')
const Pricing = () => import('../views/Pages/Pricing')
const Pricing1 = () => import('../views/Pages/Pricing1')
const BlankPage = () => import('../views/Pages/BlankPage')
const FAQ = () => import('../views/Pages/FAQ')
const Invoice = () => import('../views/Pages/Invoice')
/* Apps Views */
const EmailListing = () => import('../views/Apps/Email/EmailListing')
const EmailCompose = () => import('../views/Apps/Email/EmailCompose')
const Calendar = () => import('../views/Apps/Calendar/Calendar')
/* Icon Views */
const IconDripicons = () => import('../views/Icons/IconDripicons')
const IconFontawesome5 = () => import('../views/Icons/IconFontawesome5')
const IconLineAwesome = () => import('../views/Icons/IconLineAwesome')
const IconRemixicon = () => import('../views/Icons/IconRemixicon')
const IconUnicons = () => import('../views/Icons/IconUnicons')
/* Tables Views */
const TablesBasic = () => import('../views/Tables/TablesBasic')
const EditableTable = () => import('../views/Tables/EditableTable')
/* Form View */
const FormLayout = () => import('../views/Forms/FormLayout')
const FormValidates = () => import('../views/Forms/FormValidates')
const FormSwitches = () => import('../views/Forms/FormSwitches')
const FormRadios = () => import('../views/Forms/FormRadios')
const FormCheckboxes = () => import('../views/Forms/FormCheckboxes')
/* User View */
const Profile = () => import('../views/User/Profile')
const ProfileEdit = () => import('../views/User/ProfileEdit')
const AddUser = () => import('../views/User/AddUser')
const SocialApp = () => import('../views/Apps/Social/index')
/* Todo */
const TodoListing = () => import('../views/Apps/Todo/TodoListing')
const ChatIndex = () => import('../views/Apps/Chat/Index')
const AccountSettings = () => import('../views/User/AccountSetting')
const Article = () => import('../views/Pages/Article')

/* Api Integarted Pages */
Vue.use(VueRouter)
const minRoleType = {
  1: 'USER',
  2: 'ADMIN'
}
const ROLES_TYPE = { 'ADMIN': 2, 'USER': 1, 'INFLUENCER': 3 }
const ENV = 'production'

const childRoutes = (prop, mode) => [
  {
    path: '/',
    name: prop + '.list',
    meta: { auth: true, name: 'Social App' },
    component: SocialApp
  },
  {
    path: '/profile',
    name: prop + '.profile',
    meta: { auth: true, name: 'Profile' },
    component: SocialProfile
  },
  {
    path: '/influencers',
    name: prop + '.influencers',
    meta: { auth: true, name: 'Profile' },
    component: Influencers
  },
  {
    path: '/influencers/:id/:name',
    name: prop + '.influencerProfile',
    meta: { auth: true, name: 'InfulencerProfile' },
    component: InfluencerProfile
  },
  {
    path: '/article/:contentId',
    name: prop + '.article',
    meta: { auth: true, name: 'Article' },
    props: true,
    component: Article
  },
  {
    path: '/video-content/:contentId',
    name: prop + '.videoContentScreen',
    meta: { auth: true, name: 'VideoContentScreen' },
    props: true,
    component: VideoContentScreen
  },
  {
    path: '/my-courses',
    name: prop + '.myCourses',
    meta: { auth: true, name: 'MyCourses' },
    props: true,
    component: MyCourses
  },
  {
    path: 'friend-list',
    name: prop + '.friend-list',
    meta: { auth: true, name: 'Friend List', img: require('../assets/images/page-img/profile-bg3.jpg') },
    component: FriendList
  },
  {
    path: 'group',
    name: prop + '.group',
    meta: { auth: true, name: 'Group', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: Group
  },
  {
    path: 'profile-image',
    name: prop + '.profile-image',
    meta: { auth: true, name: 'Your Photos', img: require('../assets/images/page-img/profile-bg5.jpg') },
    component: ProfileImage
  },
  {
    path: 'profile-video',
    name: prop + '.profile-video',
    meta: { auth: true, name: 'Profile Video', img: require('../assets/images/page-img/profile-bg9.jpg') },
    component: ProfileVideo
  },
  {
    path: 'profile-event',
    name: prop + '.profile-event',
    meta: { auth: true, name: 'Your Event', img: require('../assets/images/page-img/profile-bg6.jpg') },
    component: ProfileEvent
  },
  {
    path: 'profile-badges',
    name: prop + '.profile-badges',
    meta: { auth: true, name: 'Profile Badges' },
    component: ProfileBadges
  },
  {
    path: 'profile-forum',
    name: prop + '.profile-forum',
    meta: { auth: true, name: 'Profile Forum' },
    component: ProfileForum
  },
  {
    path: 'notification',
    name: prop + '.notification',
    meta: { auth: true, name: 'Notification' },
    component: Notification
  },
  {
    path: 'file',
    name: prop + '.file',
    meta: { auth: true, name: 'File' },
    component: File
  },
  {
    path: 'friend-request',
    name: prop + '.friend-request',
    meta: { auth: true, name: 'Friend Request' },
    component: FriendRequest
  },
  {
    path: 'birthday',
    name: prop + '.birthday',
    meta: { auth: true, name: 'Birthday', img: require('../assets/images/page-img/profile-bg4.jpg') },
    component: Birthday
  },
  {
    path: 'weather',
    name: prop + '.weather',
    meta: { auth: true, name: 'Weather', img: require('../assets/images/page-img/profile-bg2.jpg') },
    component: Weather
  },
  {
    path: '/account-setting',
    name: 'accountSetting',
    meta: { auth: true, name: 'AccountSettings' },
    component: AccountSettings
  },
  {
    path: '/peers',
    name: prop + '.peers',
    meta: { auth: true, name: 'Peers' },
    component: ComingSoon
  },
  {
    path: '/messages',
    name: prop + '.messages',
    meta: { auth: true, name: 'Peers' },
    component: Messages
  }
]

const coreChildRoute = (prop, mode) => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { auth: true, name: 'Colors' },
    component: UiColors
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { auth: true, name: 'Typography' },
    component: UiTypography
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { auth: true, name: 'Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { auth: true, name: 'Button' },
    component: UiButtons
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { auth: true, name: 'Badges' },
    component: UiBadges
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { auth: true, name: 'Card' },
    component: UiCards
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { auth: true, name: 'Carousel' },
    component: UiCarousel
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { auth: true, name: 'Grid' },
    component: UiGrid
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { auth: true, name: 'Embed Video' },
    component: UiEmbedVideo
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { auth: true, name: 'Model' },
    component: UiModal
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { auth: true, name: 'List Group' },
    component: UiListGroup
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { auth: true, name: 'Image' },
    component: UiImages
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { auth: true, name: 'Media Object' },
    component: UiMediaObject
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { auth: true, name: 'Paginations' },
    component: UiPagination
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { auth: true, name: 'Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { auth: true, name: 'Tooltip' },
    component: UiTooltips
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { auth: true, name: 'Popover' },
    component: UiPopovers
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { auth: true, name: 'Notification' },
    component: UiNotifications
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { auth: true, name: 'Tabs' },
    component: UiTabs
  }
]
const formChildRoute = (prop, mode = false) => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { auth: true, name: 'Layout' },
    component: FormLayout
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { auth: true, name: 'Validate' },
    component: FormValidates
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { auth: true, name: 'Switch' },
    component: FormSwitches
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { auth: true, name: 'Radio' },
    component: FormRadios
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { auth: true, name: 'Checkbox' },
    component: FormCheckboxes
  }
]

const tableChildRoute = (prop, mode = false) => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { auth: true, name: 'Basic' },
    component: TablesBasic
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { auth: true, name: 'Editable' },
    component: EditableTable
  }
]

const iconChildRoute = (prop, mode = false) => [
  {
    path: 'dripicons',
    name: prop + '.dripicons',
    meta: { auth: true, name: 'Dripicons' },
    component: IconDripicons
  },
  {
    path: 'fontawesome-5',
    name: prop + '.fontawesome-5',
    meta: { auth: true, name: 'Font Awsome' },
    component: IconFontawesome5
  },
  {
    path: 'lineawesome',
    name: prop + '.lineawesome',
    meta: { auth: true, name: 'Line Awsome' },
    component: IconLineAwesome
  },
  {
    path: 'remixicon',
    name: prop + '.remixicon',
    meta: { auth: true, name: 'Remixicon' },
    component: IconRemixicon
  },
  {
    path: 'unicons',
    name: prop + '.unicons',
    meta: { auth: true, name: 'Unicon' },
    component: IconUnicons
  }
]

const appChildRoute = (prop, mode = false) => [
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: { auth: true, name: 'Calendar', img: require('../assets/images/page-img/profile-bg6.jpg') },
    component: Calendar
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { auth: true, name: 'Chat' },
    component: ChatIndex
  },
  {
    path: 'todo',
    name: prop + '.todo',
    meta: { auth: true, name: 'Todo' },
    component: TodoListing
  },
  {
    path: 'email',
    name: prop + '.email',
    meta: { auth: true, name: 'Email' },
    component: EmailListing
  },
  {
    path: 'email-compose',
    name: prop + '.email.compose',
    meta: { auth: true, name: 'New Email' },
    component: EmailCompose
  }
]

const authChildRoutes = (prop, mode = false) => [
  {
    path: 'sign-in1',
    name: prop + '.sign-in1',
    meta: { auth: true },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: 'email-verification/:otp',
    name: prop + '.email-verification',
    meta: { auth: true },
    component: EmailVerification
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { auth: true },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { auth: true },
    component: LockScreen1
  },
  {
    path: 'confirm-mail1/:prop',
    name: prop + '.confirm-mail1',
    meta: { auth: true },
    component: ConfirmMail1,
    props: true
  },
  {
    path: 'sign-in-linkedin',
    name: prop + '.sign-in-linkedin',
    meta: { auth: true },
    component: SignIn1,
    beforeEnter (to, from, next) {
      linkedinLogin(next)
    }

  },
  {
    path: 'reset-password/:id',
    name: prop + 'reset-password',
    meta: { auth: true },
    component: ResetPassword
  }
]
const defaultlayout = (prop, mode = false) => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { auth: true, name: 'Timeline' },
    component: TimeLine
  },
  {
    path: 'invoice',
    name: prop + '.invoice',
    meta: { auth: true, name: 'Invoice' },
    component: Invoice
  },
  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { auth: true, name: 'Blank Page' },
    component: BlankPage
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { auth: true, name: 'Pricing' },
    component: Pricing
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { auth: true, name: 'Pricing 1' },
    component: Pricing1
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true, name: 'Faq' },
    component: FAQ
  }
]

const pagesChildRoutes = (prop, mode = false) => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { auth: true },
    component: ErrorPage
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { auth: true },
    component: ComingSoon
  },
  {
    path: 'maintenance',
    name: prop + '.maintenance',
    meta: { auth: true },
    component: Maintenance
  }
]
const userChildRoute = (prop, mode = false) => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'profile-edit',
    name: prop + '.edit',
    meta: { auth: true, name: 'Edit Profile' },
    component: ProfileEdit
  },
  {
    path: 'add-user',
    name: prop + '.add',
    meta: { auth: true, name: 'Add Profile' },
    component: AddUser
  }
]
const blogchildRoutes = (prop, mode = false) => [
  {
    path: 'blog-grid',
    name: prop + '.blog-grid',
    meta: { auth: true, name: 'blog-grid' },
    component: BlogGrid
  },
  {
    path: 'blog-list',
    name: prop + '.blog-list',
    meta: { auth: true, name: 'blog-list' },
    component: BlogList
  },
  {
    path: 'blog-detail',
    name: prop + '.blog-detail',
    meta: { auth: true, name: 'blog-detail' },
    component: BlogDetail
  }
]
const storechildRoutes = (prop, mode = false) => [
  {
    path: 'category-grid',
    name: prop + '.category-grid',
    meta: { auth: true, name: 'Store Category', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: CategoryGrid
  },
  {
    path: 'category-list',
    name: prop + '.category-list',
    meta: { auth: true, name: 'Store Category ', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: CategoryList
  },
  {
    path: 'store-detail',
    name: prop + '.store-detail',
    meta: { auth: true, name: 'Store', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: StoreDetail
  },
  {
    path: 'checkout',
    name: prop + '.store-checkout',
    meta: { auth: true, name: 'Store Checkout', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: StoreCheckout
  },
  {
    path: 'checkout-address',
    name: prop + '.store-checkoutaddress',
    meta: { auth: true, name: 'Store Checkout', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: StoreCheckoutaddress
  },
  {
    path: 'checkout-payment',
    name: prop + '.store-checkoutpayment',
    meta: { auth: true, name: 'Store Checkout', img: require('../assets/images/page-img/profile-bg7.jpg') },
    component: StoreCheckoutpayment
  }

]
const routes = [
  {
    path: '/',
    name: 'social',
    component: Layout,
    children: childRoutes('social'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/core',
    name: 'core',
    component: Layout,
    meta: { auth: true },
    children: coreChildRoute('core'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/table',
    name: 'table',
    component: Layout,
    meta: { auth: true },
    children: tableChildRoute('table'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/icon',
    name: 'icon',
    component: Layout,
    meta: { auth: true },
    children: iconChildRoute('icon'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout,
    meta: { auth: true },
    children: defaultlayout('extra-pages'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/app',
    name: 'app',
    component: Layout,
    meta: { auth: true },
    children: appChildRoute('app'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/user',
    name: 'user',
    component: Layout,
    meta: { auth: true },
    children: userChildRoute('user'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/form',
    name: 'form',
    component: Layout,
    meta: { auth: true },
    children: formChildRoute('form'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: Layout,
    children: blogchildRoutes('blog'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  },
  {
    path: '/store',
    name: 'store',
    component: Layout,
    children: storechildRoutes('store'),
    beforeEnter (to, from, next) {
      checkAuthorisation(to, from, next, minRoleType.user)
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})
function checkAuthorisation (to, from, next, minRoleType) {
  const accessToken = localStorage.getItem('lxp_auth_token')
  if (!accessToken) {
    authUtils.goToLogin()
  } else {
    let accessToken = localStorage.getItem('lxp_auth_token')
    if (accessToken != null) {
      store.dispatch('GET_USER', {
        success: user => {
          checkRoles(next, user, ENV, minRoleType)
        }
      })
    } else {
      authUtils.goToLogin()
    }
  }
}
// function buildLoginPayload (email, password) {
//   return { 'email': email, 'password': password }
// }

function checkRoles (next, user, ENV, minRoleType) {
  if (user.orgRoles && user.orgRoles[1]) {
    var roles = user.orgRoles[1]
    if (isExist(roles, minRoleType) && ENV === 'production') {
      store.dispatch('SET_AUTH', true)
      next()
    } else if (ENV === 'development') {
      store.dispatch('SET_AUTH', true)
      next()
    } else {
      alert('Insufficient permissions. Please contact admin')
    }
  } else {
    alert('Insufficient permissions. Please contact admin')
    store.dispatch('CLEAR_USER')
    authUtils.goToLogout()
  }
}
function isExist (roles, minRoleType) {
  let isValid = false
  let superUser = false
  if (roles) {
    roles.forEach(el => {
      if (el === ROLES_TYPE.ADMIN) {
        superUser = true
      } else if (el === ROLES_TYPE.INFLUENCER) {
        superUser = true
      }
    })
    if (superUser) {
      isValid = true
    } else {
      if (roles[0] === ROLES_TYPE.USER) {
        isValid = true
      }
    }
  }
  return isValid
}
async function linkedinLogin (next) {
  var params = deserializeQueryParams(window.location.search)
  const urlParams = new URLSearchParams(params)
  const code = urlParams.get('code')
  if (code) {
    const payload = {
      key: Constants.APP_LINKEDIN_REDIRECT_URI,
      value: code,
      loginSource: 'LINKEDIN'
    }
    await store.dispatch('LOGIN', {
      payload: payload,
      success: () => { window.location.href = Constants.APP_HOME }
    })
  }
  next()
}

export default router
