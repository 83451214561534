import router from '../../router/index'
export default {
  goToLogin () {
    // utils.resetSession()
    window.location.href = '/auth/sign-in1'
  },
  goToHome () {
    router.push({ name: 'social.list' })
  },
  goToLogout () {
    router.push({ name: 'auth.sign-in1' })
  },
  goToPageNotFound () {
    router.push({ path: `/pageNotFound` })
  }
}
