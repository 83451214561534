// import { delete } from "vue/types/umd"

// const LXP_BASE = 'https://lxp-backend.proto.zoomiinc.com'
const LXP_AUTH = '/account/v1'
const LXP_CORE = '/core/v1/'
const LXP_MESSAGES = LXP_AUTH + '/messages/'
export default {
  auth: {
    auth_check: 'check',
    user: LXP_AUTH + '/session',
    logout: LXP_AUTH + '/logout',
    login: LXP_AUTH + '/login',
    login_page: LXP_AUTH + '/auth/sign-in1',
    register: LXP_AUTH + '/register',
    confirm_email: LXP_AUTH + '/verify-email',
    recoverPassword: LXP_AUTH + '/forgot',
    setPassword: LXP_AUTH + '/set-password',
    getChatContacts: LXP_MESSAGES + 'contacts',
    sendMessage: LXP_MESSAGES + 'send',
    searchUsers: LXP_MESSAGES + 'search',
    getMessage: (topic) => LXP_MESSAGES + `${topic}`,
    fileUpload: LXP_AUTH + '/presignedurl'

  },
  core: {
    home_page: LXP_CORE + 'home',
    update_user_content_state: (contentId) => LXP_CORE + `users/contents/${contentId}`,
    get_user_content: (contentId) => LXP_CORE + `users/contents/${contentId}`,
    get_all_reviews: (contentId) => LXP_CORE + `contents/${contentId}/reviews`,
    delete_user_review: (contentId) => LXP_CORE + `users/contents/reviews/${contentId}`,
    // Playlist APi's
    create_user_playlist: LXP_CORE + 'users/playlists',
    update_user_playlist: (playlistId) => LXP_CORE + `users/playlists/${playlistId}`,
    add_content_user_playlist: LXP_CORE + 'users/playlists/content',
    get_saved_contents: LXP_CORE + 'users/contents/saved',
    get_user_history: LXP_CORE + 'users/contents/history',
    get_user_contents: LXP_CORE + 'users/contents',
    // Search Api
    search: LXP_CORE + 'search',
    // Influencers Page APi's
    get_influencer_profile: LXP_AUTH + '/get-profile',
    follow_inflluencer: LXP_AUTH + '/follow',
    influencer_most_followed: LXP_AUTH + '/influencers/most-followed',
    influencer_most_liked: LXP_AUTH + '/influencers/most-liked',
    influencer_most_viewed: LXP_AUTH + '/influencers/most-viewed',
    influencer_movers_shakers: LXP_AUTH + '/influencers/movers-and-shakers',
    influencers_playlist: LXP_CORE + 'influencers/playlist',
    influencer_playlist: (influencerId) => LXP_CORE + `influencers/${influencerId}/playlist`,
    influencer_feed_playlist: (influencerId) => LXP_CORE + `influencers/${influencerId}/playlists`,
    influencer_feed_category: (influencerId) => LXP_CORE + `influencers/${influencerId}/collections`,
    // Notification Api's
    get_notifications: LXP_AUTH + '/notifications',
    mark_notifications: LXP_AUTH + '/notifications/mark'
  }
}
