import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getUser (callback, fail) {
    apiCall.makeGetRequestForUser(apiEndPoints.auth.user, callback, fail)
  },
  login (callback, fail, body) {
    apiCall.makePostRequestLogin(apiEndPoints.auth.login, callback, fail, body)
  },
  register (callback, fail, body) {
    apiCall.makePostRequestLogin(apiEndPoints.auth.register, callback, fail, body)
  },
  confirmEmail (callback, fail, body) {
    apiCall.makePostRequestLogin(apiEndPoints.auth.confirm_email, callback, fail, body)
  },
  recoverPassword (callback, fail, body) {
    apiCall.makePostRequestLogin(apiEndPoints.auth.recoverPassword, callback, fail, body)
  },
  setPassword (callback, fail, body) {
    apiCall.makePostRequestLogin(apiEndPoints.auth.setPassword, callback, fail, body)
  }

}
