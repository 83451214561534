
export default {
  state: {
    analyticsState: {
      newUser: false,
      loggedUser: null,
      page: null,
      type: null,
      action: null,
      name: null,
      contentId: null,
      category: null,
      publisherId: null,
      publisherName: null,
      rating: 0,
      review: null,
      bookmark: false,
      like: false,
      searchKeyword: null,
      userId: null, // Influencer userId
      follow: false,
      speedRate: null,
      completed: false,
      windowFocus: false,
      fullscreen: false,
      prevPlaybackPosition: null,
      playbackPosition: null,
      totalDuration: null,
      noteId: null,
      playlistId: null,
      topic: null,
      level: null,
      isInfluencer: false
    }

  },

  getters: {
    getAnalyticsState (state) {
      return state.analyticsState
    }
  },

  mutations: {
    setLoggedUser (state, value) {
      state.analyticsState.loggedUser = value
    },
    setPage (state, value) {
      state.analyticsState.page = value
    },
    setType (state, value) {
      state.analyticsState.type = value
    },
    setAction (state, value) {
      state.analyticsState.action = value
    },
    setName (state, value) {
      state.analyticsState.name = value
    },
    setContentId (state, value) {
      state.analyticsState.contentId = value
    },
    setCategory (state, value) {
      state.analyticsState.category = value
    },
    setPublisherId (state, value) {
      state.analyticsState.publisherId = value
    },
    setPublisherName (state, value) {
      state.analyticsState.publisherName = value
    },
    setRating (state, value) {
      state.analyticsState.rating = value
    },
    setReview (state, value) {
      state.analyticsState.review = value
    },
    setBookmark (state, value) {
      state.analyticsState.bookmark = value
    },
    setLike (state, value) {
      state.analyticsState.like = value
    },
    setSearchKeyword (state, value) {
      state.analyticsState.searchKeyword = value
    },
    setUserId (state, value) {
      state.analyticsState.userId = value
    },
    setFollow (state, value) {
      state.analyticsState.follow = value
    },
    setSpeedRate (state, value) {
      state.analyticsState.speedRate = value
    },
    setCompleted (state, value) {
      state.analyticsState.completed = value
    },
    setWindowFocus (state, value) {
      state.analyticsState.windowFocus = value
    },
    setFullscreen (state, value) {
      state.analyticsState.fullscreen = value
    },
    setPrevPlaybackPosition (state, value) {
      state.analyticsState.prevPlaybackPosition = value
    },
    setPlaybackPosition (state, value) {
      state.analyticsState.playbackPosition = value
    },
    setTotalDuration (state, value) {
      state.analyticsState.totalDuration = value
    },
    setNoteId (state, value) {
      state.analyticsState.noteId = value
    },
    setPlaylistId (state, value) {
      state.analyticsState.playlistId = value
    },
    setTopic (state, value) {
      state.analyticsState.topic = value
    },
    setLevel (state, value) {
      state.analyticsState.level = value
    },
    setIsInfluencer (state, value) {
      state.analyticsState.isInfluencer = value
    },
    setNewUser (state, value) {
      state.analyticsState.newUser = value
    }
  },
  actions: {
    RESET_STATE ({ state }) {
      console.log('Resetting State')
      state.analyticsState.name = null
      state.analyticsState.type = null
      state.analyticsState.action = null
      state.analyticsState.contentId = null
      state.analyticsState.category = null
      state.analyticsState.publisherId = null
      state.analyticsState.publisherName = null
      state.analyticsState.rating = null
      state.analyticsState.review = null
      state.analyticsState.bookmark = null
      state.analyticsState.like = null
      state.analyticsState.searchKeyword = null
      state.analyticsState.userId = null
      state.analyticsState.follow = null
      state.analyticsState.speedRate = null
      state.analyticsState.completed = null
      state.analyticsState.windowFocus = null
      state.analyticsState.prevPlaybackPosition = null
      state.analyticsState.playbackPosition = null
      state.analyticsState.totalDuration = null
      state.analyticsState.noteId = null
      state.analyticsState.playlistId = null
      state.analyticsState.topic = null
      state.analyticsState.level = null
      state.analyticsState.isInfluencer = false
    }
  }

}
