import api from '@/api/messagesApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    searchUsers: [],
    contacts: [],
    userMessageHistory: [],
    openMessageModal: false,
    currentChatUser: {},
    sentMessageResponse: {},
    messageAttachment: '',
    currentPage: 0,
    loadingChatHistoryStatus: false,
    searchStatus: false,
    contactPageData: [],
    currentContactId: null,
    fileUploadUrl: null
  },

  getters: {
    getSearchUsers (state) {
      return state.searchUsers
    },
    getContacts (state) {
      return state.contacts
    },
    getUserMessageHistory (state) {
      return state.userMessageHistory
    },
    getOpenMessageModal (state) {
      return state.openMessageModal
    },
    getCurrentChatUser (state) {
      return state.currentChatUser
    },
    getLoadingChatHistoryStatus (state) {
      return state.loadingChatHistoryStatus
    },
    getContactPageData (state) {
      return state.contactPageData
    },
    getContactId (state) {
      return state.currentContactId
    },
    getSearchStatus (state) {
      return state.searchStatus
    },
    getSentMessageResponse (state) {
      return state.sentMessageResponse
    },
    getFileUploadUrl (state) {
      return state.fileUploadUrl
    },
    getMessageAttachment (state) {
      return state.messageAttachment
    },
    getCurrentPage (state) {
      return state.currentPage
    }
  },

  mutations: {
    setSearchUsers (state, value) {
      state.searchUsers = value
    },
    setContacts (state, value) {
      state.contacts = value
    },
    setUserMessageHistory (state, value) {
      state.userMessageHistory = value
    },
    setOpenMessageModal (state, value) {
      state.openMessageModal = value
    },
    setCurrentChatUser (state, value) {
      state.currentChatUser = value
    },
    setLoadingChatHistoryStatus (state, value) {
      state.loadingChatHistoryStatus = value
    },
    setContactPageData (state, value) {
      state.contactPageData = value
    },
    setContactId (state, value) {
      state.currentContactId = value
    },
    appendToChat (state, value) {
      state.userMessageHistory = value.concat(state.userMessageHistory)
    },
    setSearchStatus (state, value) {
      state.searchStatus = value
    },
    setSentMessageResponse (state, value) {
      state.sentMessageResponse = value
    },
    setFileUploadUrl (state, value) {
      state.fileUploadUrl = value
    },
    setFileAttachment (state, value) {
      state.messageAttachment = value
    },
    setCurrentPage (state, value) {
      state.currentPage = value
    }
  },

  actions: {
    GET_CONTACTS ({ commit, dispatch }) {
      api.getChatContacts(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setContacts', response.data.responseObject)
            // console.log('response.data:', response.data.responseObject)
            // TODO: The Content of cards are not getting updated, may need to add success() here
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },
    GET_USER_MESSAGE_HISTORY ({ commit, dispatch }, { topicId, success }) {
      api.getMessage(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setContactPageData', response.data.responseObject.pages)
            commit('setUserMessageHistory', response.data.responseObject.messages)
            success()
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      topicId
      )
    },
    GET_USER_MESSAGE_HISTORY_SECOND ({ commit, dispatch }, { topicId, success, payload, option }) {
      api.getMessageOnly(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            console.log('In Second Call')
            if (option === 'APPEND') {
              console.log('Appending')
              commit('appendToChat', response.data.responseObject.messages)
            } else {
              console.log('New Search Message')
              commit('setUserMessageHistory', response.data.responseObject.messages)
            }
            success()
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      topicId,
      payload
      )
    },
    SEND_MESSAGE ({ commit, dispatch }, { payload, success }) {
      api.sendMessage(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setSentMessageResponse', response.data.responseObject)
            success()
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    SEARCH_USER ({ commit, dispatch }, { params, success }) {
      api.searchUsers(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setSearchUsers', response.data.responseObject)
            success()
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_PRESIGNED_URL ({ commit, dispatch }, { payload, success }) {
      api.uploadFile(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setFileUploadUrl', response.data.responseObject[0])
            success()
          } else {
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    UPLOAD_TO_S3 ({ commit, dispatch }, { payload, success, data }) {
      api.uploadToS3(response => {
        console.log('Doing Api Call')
        success()
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload,
      data
      )
    },

    SET_MESSAGE_MODAL_STATE ({ commit, dispatch }, payload) {
      commit('setOpenMessageModal', payload)
    },
    SET_CURRENT_ACTIVE_CHAT_USER_STATE ({ commit, dispatch }, payload) {
      commit('setCurrentChatUser', payload)
    },
    SET_CHAT_HISTORY_LOADING ({ commit, dispatch }, payload) {
      commit('setLoadingChatHistoryStatus', payload)
    },
    SET_CURRENT_CHAT_ID ({ commit, dispatch }, payload) {
      commit('setContactId', payload)
    },
    SET_SEARCH_STATUS ({ commit, dispatch }, payload) {
      commit('setSearchStatus', payload)
    },
    SET_SEARCHED_MESSAGE ({ commit, dispatch }, payload) {
      commit('setUserMessageHistory', payload)
    },
    SET_MESSAGE_ATTACHMENT ({ commit, dispatch }, { payload, success }) {
      commit('setFileAttachment', payload)
      success()
    },
    SET_CURRENT_PAGE ({ commit, dispatch }, payload) {
      console.log('Current Page Payload' + payload)
      commit('setCurrentPage', payload)
    }

  }
}
