import api from '@/api/notificationsApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    notifications: [],
    notificationCount: 0,
    individualNotificationCount: 0,
    isNotificationRead: true,
    showAttachmentModal: false
  },

  getters: {
    getNotification (state) {
      return state.notifications
    },
    getNotificationCount (state) {
      return state.notificationCount
    },
    getIndividualNotificationCount (state) {
      return state.individualNotificationCount
    },
    getAttachmentModal (state) {
      return state.showAttachmentModal
    }
  },

  mutations: {
    setNotification (state, value) {
      state.notifications = value
    },
    setNotificationCount (state, value) {
      state.notificationCount = value
    },
    setIndividualNotificationCount (state, value) {
      state.individualNotificationCount = value
    },
    setAttachmentModal (state, value) {
      console.log('In setter attach ', value)
      state.showAttachmentModal = value
    }

  },

  actions: {
    GET_NOTIFICATIONS ({ commit, dispatch }) {
      api.getNotifications(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setNotification', response.data.responseObject)
            let notification
            let count = 0
            for (notification of response.data.responseObject) {
              count = count + notification.count
            }
            commit('setNotificationCount', count)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },
    MARK_AS_READ ({ commit, dispatch }, { payload, success }) {
      api.markNotifications(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            console.log('Marked')
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    SET_INDIVIDUAL_NOTIFICATION_COUNT ({ commit, dispatch }, payload) {
      commit('setIndividualNotificationCount', payload)
    },
    SET_ATTACHMENT_MODAL_STATE ({ commit, dispatch }, payload) {
      console.log('Setting attch state', payload)
      commit('setAttachmentModal', payload)
    }

  }
}
