import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getChatContacts (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.auth.getChatContacts, callback, fail)
  },
  sendMessage (callback, fail, payload) {
    apiCall.makePostRequest(apiEndPoints.auth.sendMessage, callback, fail, payload)
  },
  searchUsers (callback, fail, params) {
    apiCall.makeGetRequest(apiEndPoints.auth.searchUsers, callback, fail, params)
  },
  getMessage (callback, fail, topic, params) {
    apiCall.makePostRequest(apiEndPoints.auth.getMessage(topic), callback, fail, params)
  },
  getMessageOnly (callback, fail, topic, payload) {
    apiCall.makePostRequest(apiEndPoints.auth.getMessage(topic), callback, fail, payload)
  },
  uploadFile (callback, fail, payload) {
    apiCall.makePostRequest(apiEndPoints.auth.fileUpload, callback, fail, payload)
  },
  uploadToS3 (callback, fail, payload, data) {
    console.log('Form Data' + data.name)
    console.log('In Upload Api Call ' + JSON.stringify(payload))
    let path = payload.uploadUri
    let header = payload.header
    apiCall.uploadFiletoS3(path, callback, fail, data, header)
  }
}
