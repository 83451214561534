import api from '@/api/myCoursesPageApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    savedContents: [],
    userHistory: [],
    userContents: [],
    errorStatus: false,
    errorMessage: ''
  },

  getters: {
    getSavedContents (state) {
      return state.savedContents
    },
    getUserHistory (state) {
      return state.userHistory
    },
    getUserContents (state) {
      return state.userContents
    }
  },

  mutations: {
    setSavedContents (state, value) {
      state.savedContents = value
    // console.log('State Content ' + JSON.stringify(state.content))
    },
    setUserHistory (state, value) {
      state.userHistory = value
    },
    setUserContents (state, value) {
      state.userContents = value
    }

  },

  actions: {
    GET_SAVED_CONTENTS ({ commit, dispatch }) {
      api.getSavedContents(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setSavedContents', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },
    GET_USER_HISTORY ({ commit, dispatch }) {
      api.getUserHistory(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setUserHistory', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },
    GET_USER_CONTENTS ({ commit, dispatch }, { params }) {
      api.getUserContents(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setUserContents', response.data.responseObject)
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    }
  }
}
