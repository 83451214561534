import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  influencerMostFollowed (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_most_followed, callback, fail)
  },
  influencerMostLiked (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_most_liked, callback, fail)
  },
  influencerProfile (callback, fail, params) {
    apiCall.makeGetRequest(apiEndPoints.core.get_influencer_profile, callback, fail, params)
  },
  setInfluencerInteractions (callback, fail, params) {
    apiCall.makePostRequest(apiEndPoints.core.follow_inflluencer, callback, fail, null, params)
  },
  getInfluencerInteractions (callback, fail, params) {
    apiCall.makeGetRequest(apiEndPoints.core.follow_inflluencer, callback, fail, params)
  },
  influencerMostViewed (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_most_viewed, callback, fail)
  },
  influencerMoversAndShakers (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_movers_shakers, callback, fail)
  },
  influencersPlayList (callback, fail, params) {
    apiCall.makeGetRequest(apiEndPoints.core.influencers_playlist, callback, fail, params)
  },
  influencerProfileCategories (callback, fail, influencerId) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_feed_category(influencerId), callback, fail)
  },
  influencerProfilePlaylist (callback, fail, influencerId) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_feed_playlist(influencerId), callback, fail)
  }
}
