import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getSavedContents (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.get_saved_contents, callback, fail)
  },
  getUserHistory (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.get_user_history, callback, fail)
  },
  getUserContents (callback, fail, params) {
    apiCall.makeGetRequest(apiEndPoints.core.get_user_contents, callback, fail, params)
  }
}
