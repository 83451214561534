import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getNotifications (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.get_notifications, callback, fail)
  },
  markNotifications (callback, fail, payload) {
    apiCall.makePostRequest(apiEndPoints.core.mark_notifications, callback, fail, payload)
  }
}
