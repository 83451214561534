import axios from 'axios'
import { serializeQueryParams } from './apiUtils'
import store from '../../store/index'

// axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
// axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With'

export default {
  makeGetRequest (path, callback, fail, params) {
    let apiHeaders = this.buildApiHeader()
    path += serializeQueryParams(params)
    axios
      .get(path, { withCredentials: true, headers: apiHeaders })
      .then(callback)
      .catch(fail)
  },

  makeGetRequestForUser (path, callback, fail) {
    let apiHeaders = this.buildApiHeader()
    axios
      .get(path, { withCredentials: true, headers: apiHeaders })
      .then(callback)
      .catch(fail)
  },

  makePostRequest (path, callback, fail, payload, params) {
    let apiHeaders = this.buildApiHeader()
    path += serializeQueryParams(params)
    axios
      .post(path, payload, { withCredentials: true, headers: apiHeaders })
      .then(callback)
      .catch(fail)
  },

  makePostRequestLogin (path, callback, fail, payload) {
    axios
      .post(path, payload)
      .then(callback)
      .catch(fail)
  },

  makeDeleteRequest (path, callback, fail, payload) {
    let apiHeaders = this.buildApiHeader()
    axios
      .delete(path, { data: payload, headers: apiHeaders })
      .then(callback)
      .catch(fail)
  },

  makeDeleteRequestForTask (path, callback, fail, payload, params) {
    let apiHeaders = this.buildApiHeader()
    path += serializeQueryParams(params)
    axios
      .delete(path, { data: payload, headers: apiHeaders })
      .then(callback)
      .catch(fail)
  },

  makePutRequest (path, callback, fail, payload, params) {
    let apiHeaders = this.buildApiHeader()
    path += serializeQueryParams(params)
    let headers = { ...apiHeaders }
    if (params) {
      headers['Content-Type'] = 'application/json'
    }
    axios
      .put(path, payload, { withCredentials: true, headers: headers })
      .then(callback)
      .catch(fail)
  },
  uploadFiletoS3 (path, callback, fail, file, header) {
    let headers = { 'Content-Type': header }
    axios
      .put(path, file, { headers: headers })
      .then(callback)
      .catch(fail)
  },
  getImageFromBlob (path, callback, fail, params, tokenSource) {
    let apiHeaders = this.buildApiHeader()
    let headers = { ...apiHeaders }
    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json;charset=UTF-8'
    path += serializeQueryParams(params)
    axios
      .get(path, {
        responseType: 'arraybuffer',
        withCredentials: true,
        headers,
        cancelToken: tokenSource.token
      })
      .then(callback)
      .catch(fail)
  },

  downloadFile (path, callback, fail, params) {
    let apiHeaders = this.buildApiHeader()
    let headers = { ...apiHeaders }
    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json;charset=UTF-8'
    path += serializeQueryParams(params)
    axios
      .get(path, {
        responseType: 'arraybuffer',
        withCredentials: true,
        headers
      })
      .then(callback)
      .catch(fail)
  },
  buildApiHeader () {
    let accessToken = store.state.authStore.accessToken
    if (!accessToken) {
      accessToken = window.localStorage.getItem('lxp_auth_token')
    }
    let authToken = 'Bearer ' + accessToken
    return {
      Authorization: authToken,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }
}
