<template>
  <router-view/>
</template>
<script>
import { socialvue } from './config/pluginInit'
export default {
  name: 'App',
  components: {
  },
  mounted () {
    socialvue.mainIndex()
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      'https://divolte.proto.zoomiinc.net/divolte.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
