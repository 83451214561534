import apiEndPoints from './apiUtils/apiEndPoints'
import apiCall from './apiUtils/makeApiCall.js'

export default {
  getHomeContent (callback, fail, payload, params) {
    apiCall.makePostRequest(apiEndPoints.core.home_page, callback, fail, payload, params)
  },
  homePageInfluencerPlaylist (callback, fail, influencerId) {
    apiCall.makeGetRequest(apiEndPoints.core.influencer_playlist(influencerId), callback, fail)
  },
  getUserContent (callback, fail, contentId) {
    apiCall.makeGetRequest(apiEndPoints.core.get_user_content(contentId), callback, fail)
  },
  getAllReviews (callback, fail, contentId) {
    apiCall.makeGetRequest(apiEndPoints.core.get_all_reviews(contentId), callback, fail)
  },
  deleteUserReview (callback, fail, contentId) {
    apiCall.makeDeleteRequest(apiEndPoints.core.delete_user_review(contentId), callback, fail)
  },
  updateUserContentState (callback, fail, contentId, payload) {
    apiCall.makePutRequest(apiEndPoints.core.update_user_content_state(contentId), callback, fail, payload)
  },
  // Playlist APi's
  getUserPlayLists (callback, fail) {
    apiCall.makeGetRequest(apiEndPoints.core.create_user_playlist, callback, fail)
  },
  addUserPlayLists (callback, fail, payload) {
    apiCall.makePostRequest(apiEndPoints.core.create_user_playlist, callback, payload, fail)
  },
  getUserPlayListsByID (callback, fail, contentId) {
    apiCall.makeGetRequest(apiEndPoints.core.update_user_playlist(contentId), callback, fail)
  },
  editUserPlayLists (callback, contentId, fail, payload) {
    apiCall.makePostRequest(apiEndPoints.core.update_user_playlist(contentId), callback, payload, fail)
  },
  deleteUserPlayLists (callback, contentId, fail) {
    apiCall.makeDeleteRequest(apiEndPoints.core.update_user_playlist(contentId), callback, fail)
  },
  addContentToUserPlayList (callback, fail, payload) {
    apiCall.makeGetRequest(apiEndPoints.core.add_content_user_playlist, callback, payload, fail)
  },
  deleteContentFromUserPlayList (callback, fail) {
    apiCall.makeDeleteRequest(apiEndPoints.core.add_content_user_playlist, callback, fail)
  },
  mostWatchedPopular (callback, fail, params) {
    apiCall.makePostRequest(apiEndPoints.core.search, callback, fail, null, params)
  }
}
