import api from '@/api/homePageApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    content: [],
    userContent: null,
    errorStatus: false,
    influencerPlaylist: [],
    most_liked: [],
    promotedFeed1: [],
    promotedFeed2: [],
    most_popular: [],
    most_popular_response: {},
    most_liked_response: {},
    reviews: [],
    isLessonRated: false,
    errorMessage: '',
    feedLoading: false
  },

  getters: {
    getContent (state) {
      return state.content
    },
    getReviews (state) {
      return state.reviews
    },
    getMostPopular (state) {
      return state.most_popular
    },
    getMostPopularResponse (state) {
      return state.most_popular_response
    },
    getMostLiked (state) {
      return state.most_liked
    },
    getMostLikedResponse (state) {
      return state.most_liked_response
    },
    getUserContent (state) {
      return state.userContent
    },
    getIsLessonRated (state) {
      return state.isLessonRated
    },
    getInfluencerPlaylist (state) {
      return state.influencerPlaylist
    },
    getPromotedFeed1 (state) {
      return state.promotedFeed1
    },
    getPromotedFeed2 (state) {
      return state.promotedFeed2
    },
    getFeedLoading (state) {
      return state.feedLoading
    }
  },

  mutations: {
    setContent (state, value) {
      state.content = value
    },
    setReviews (state, value) {
      state.reviews = value
    },
    setMostLiked (state, value) {
      state.most_liked = value
    },
    setMostPopular (state, value) {
      state.most_popular = value
    },
    appendToMostPopular (state, value) {
      state.most_popular = state.most_popular.concat(value)
    },
    appendToMostLiked (state, value) {
      state.most_liked = state.most_liked.concat(value)
    },
    setMostPopularResponse (state, value) {
      state.most_popular_response = value
    },
    setMostLikedResponse (state, value) {
      state.most_liked_response = value
    },
    setUserContent (state, value) {
      state.userContent = value
    },
    setIsLessonRated (state, value) {
      state.isLessonRated = value
    },
    setInfluencersPlaylist (state, value) {
      state.influencerPlaylist = value
    },
    setPromotedFeed1 (state, value) {
      state.promotedFeed1 = value
    },
    setPromotedFeed2 (state, value) {
      state.promotedFeed2 = value
    },
    setFeedLoading (state, value) {
      state.feedLoading = value
    }
  },

  actions: {
    GET_HOME_CONTENT ({ commit, dispatch }, { payload, params }) {
      api.getHomeContent(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setContent', response.data.responseObject)
            commit('setErrorStatus', false)
            // console.log('response.data:', response.data.responseObject)
            // TODO: The Content of cards are not getting updated, may need to add success() here
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload,
      params
      )
    },
    GET_PROMOTED_FEED_1 ({ commit, dispatch }, { payload, params }) {
      api.getHomeContent(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setPromotedFeed1', response.data.responseObject)
            commit('setErrorStatus', false)
            // console.log('response.data:', response.data.responseObject)
            // TODO: The Content of cards are not getting updated, may need to add success() here
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload,
      params
      )
    },
    GET_PROMOTED_FEED_2 ({ commit, dispatch }, { payload, params }) {
      api.getHomeContent(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setPromotedFeed2', response.data.responseObject)
            commit('setErrorStatus', false)
            // console.log('response.data:', response.data.responseObject)
            // TODO: The Content of cards are not getting updated, may need to add success() here
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload,
      params
      )
    },
    GET_USER_CONTENT ({ commit, dispatch }, { contentId, success }) {
      api.getUserContent(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setUserContent', response.data.responseObject)
            commit('setIsLessonRated', !(response.data.responseObject.content.rating.averageRating === 0))
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      contentId
      )
    },
    GET_ALL_REVIEWS ({ commit, dispatch }, { contentId, success }) {
      api.getAllReviews(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setReviews', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      contentId
      )
    },
    GET_INFLUENCER_PLAYLIST ({ commit, dispatch }, { influencerId, success }) {
      console.log('Inluencer Id in Store' + influencerId)
      api.homePageInfluencerPlaylist(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setInfluencersPlaylist', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      influencerId
      )
    },
    UPDATE_USER_CONTENT_STATE ({ commit, dispatch }, { contentId, payload }) {
      api.updateUserContentState(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setErrorStatus', false)
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      contentId,
      payload
      )
    },
    GET_MOST_POPULAR ({ commit, dispatch }, { params, append, success }) {
      console.log('Action Append value ' + append)
      api.mostWatchedPopular(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            if (append) {
              commit('appendToMostPopular', response.data.responseObject.contents)
            } else {
              commit('setMostPopular', response.data.responseObject.contents)
            }
            commit('setMostPopularResponse', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    GET_MOST_LIKED ({ commit, dispatch }, { params, append, success }) {
      api.mostWatchedPopular(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            if (append) {
              commit('appendToMostLiked', response.data.responseObject.contents)
            } else {
              commit('setMostLiked', response.data.responseObject.contents)
            }
            commit('setMostLikedResponse', response.data.responseObject)
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      params
      )
    },
    DELETE_USER_REVIEW ({ commit, dispatch }, { contentId, success }) {
      api.deleteUserReview(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      contentId
      )
    },
    SET_FEED_LOADING ({ commit, dispatch }, payload) {
      commit('setFeedLoading', payload)
    }

  }
}
