import authUtils from '../apiUtils/authUtils'

export default {
  handleErrors (dispatch, err) {
    const errorObj = err.response
    if (errorObj) {
      if (errorObj.status === 401 || errorObj.status === '401' || errorObj.status === 403 || errorObj.status === '403' || errorObj.status === 503 || errorObj.status === '503') {
        authUtils.goToLogin()
      } else {
        dispatch('setErrorPopup', {
          isErrorPopupVisible: true,
          errorList: [{ message: err.message }]
        })
      }
    }
  },
  handleAuthErrors (dispatch, err) {
    const errorObj = err.response
    if (errorObj) {
      if (errorObj.status === 403 || errorObj.status === '403') {
        alert('Insufficient permissions. Please contact admin')
        authUtils.goToLogout()
      } else if (errorObj.status === 401 || errorObj.status === '401') {
        authUtils.goToLogin()
      } else if (errorObj.status === 503 || errorObj.status === '503' || errorObj.status === 504 || errorObj.status === '504') {
        authUtils.goToPageNotFound()
      } else {
        dispatch('setErrorPopup', {
          isErrorPopupVisible: true,
          errorList: [{ message: err.message }]
        })
      }
    }
  }
}
