import api from '@/api/authenticationApi'
import errorChecker from '@/api/apiUtils/errorChecker'
import authUtils from '@/api/apiUtils/authUtils'
import errorHandler from '@/api/apiUtils/errorHandler'

export default {
  state: {
    user: {},
    isAuthenticated: false,
    isAdmin: false,
    accessToken: null,
    userRole: null,
    newUser: false,
    loginStatus: null,
    errorStatus: false,
    errorMessage: '',
    showLoader: false
  },

  getters: {
    getUser (state) {
      return state.user
    },
    getIsAuthenticated (state) {
      return state.isAuthenticated
    },
    getLoginStatus (state) {
      return state.loginStatus
    },
    getIsAdmin (state) {
      return state.isAdmin
    },
    getErrorStatus (state) {
      return state.errorStatus
    },
    getErrorMessage (state) {
      return state.errorMessage
    },
    getUserRole (state) {
      return state.userRole
    },
    getAccessToken (state) {
      return state.accessToken
    },
    getShowLoader (state) {
      return state.showLoader
    },
    getNewUser (state) {
      return state.newUser
    }
  },

  mutations: {
    setAccessToken (state, value) {
      state.accessToken = value
    },
    setUser (state, { value, success }) {
      localStorage.setItem('user', JSON.stringify(value))
      state.user = value
      state.isAuthenticated = true
      if (state.user) {
        if (state.user.roles) {
          for (let i = 0; i < state.user.roles.length; i++) {
            if (state.user.roles[i].id === 3) {
              state.isAdmin = true
              state.userRole = state.user.roles[i].id
              break
            } else if (state.user.roles[i].id > state.userRole) {
              state.userRole = state.user.roles[i].id
            }
          }
        }
        success(state.user)
      } else {
        authUtils.goToLogin()
      }
    },

    clearUser (state, value) {
      state.user = {}
    },
    setIsAuthenticated (state, value) {
      state.isAuthenticated = value
    },
    setLoginStatus (state, value) {
      state.loginStatus = value
    },
    setErrorStatus (state, value) {
      state.errorStatus = value
    },
    setErrorMessage (state, value) {
      state.errorMessage = value
    },
    setIsAdmin (state, value) {
      if (value.roles && value.roles[0] && value.roles[0] === 'GAME_CENTER_ADMIN') {
        state.isAdmin = true
      } else {
        state.isAdmin = false
      }
    },
    setShowLoader (state, value) {
      state.showLoader = value
    },
    setNewUser (state, value) {
      state.newUser = value
    }
  },

  actions: {
    GET_USER ({ commit, dispatch }, { success }) {
      api.getUser(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          commit('setUser', { value: response.data.responseObject, success })
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error)
      )
    },

    async LOGIN ({ commit, dispatch }, { payload, success, failure }) {
      api.login(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            let accessToken = response.data.responseObject.oauth2AccessToken.access_token
            commit('setErrorStatus', false)
            success(
              commit('setIsAuthenticated', true),
              localStorage.setItem('lxp_auth_token', accessToken)
            )
            if (response.data.responseObject.newUser) {
              console.log('New User Encountered')
              commit('setNewUser', true)
            }
          } else {
            commit('setIsAuthenticated', false)
            commit('setLoginStatus', response.data.apiResponseStatus.message)
            failure()
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    REGISTER ({ commit, dispatch }, { payload, success }) {
      api.register(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    CONFIRM_EMAIL ({ commit, dispatch }, { payload, success }) {
      api.confirmEmail((response) => {
        const errorObj = errorChecker(response)

        if (errorObj.value) {

          // what to do here?

        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            success && success()
          }
        }
      },

      (error) => errorHandler.handleAuthErrors(dispatch, error),

      payload

      )
    },
    RECOVER_PASSWORD ({ commit, dispatch }, { payload, success }) {
      api.recoverPassword(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },
    SET_PASSWORD ({ commit, dispatch }, { payload, success }) {
      api.setPassword(response => {
        const errorObj = errorChecker(response)
        if (errorObj.value) {
          authUtils.goToLogin()
        } else {
          if (response.data.apiResponseStatus.code === 1000 && response.data.apiResponseStatus.message === 'Success') {
            commit('setErrorStatus', false)
            success()
          } else {
            commit('setErrorStatus', true)
            commit('setErrorMessage', response.data.apiResponseStatus.message)
          }
        }
      },
      (error) => errorHandler.handleAuthErrors(dispatch, error),
      payload
      )
    },

    CLEAR_USER ({ commit, dispatch }) {
      commit('clearUser')
    },
    SET_AUTH ({ commit, dispatch }, isAuthenticated) {
      commit('setIsAuthenticated', isAuthenticated)
    },
    SET_ACCESS_TOKEN ({ commit, dispatch }, accessToken) {
      commit('setAccessToken', accessToken)
    }
  }
}
