import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import i18n from './i18n'
import GoogleAuth from '@/config/google_oAuth.js'
import firebase from 'firebase/app'

global.Raphael = Raphael
const gauthOption = {
  clientId: '891477494500-dm0i8ofjudseolq0mv5rbr9l0f2ocreq.apps.googleusercontent.com',
  scope: 'https://www.googleapis.com/auth/userinfo.email',
  ClientSecret: '0Nk13dYDNcQcQwvAxg7lGd7d',
  prompt: 'select_account'
}
const firebaseConfig = {
  apiKey: 'AIzaSyCpv47GPGi6qc3DlDZ_eLsefUlblUlDgz8',
  authDomain: 'zoomi-lxp-proto-e919f.firebaseapp.com',
  projectId: 'zoomi-lxp-proto-e919f',
  storageBucket: 'zoomi-lxp-proto-e919f.appspot.com',
  messagingSenderId: '408703324704',
  appId: '1:408703324704:web:457309892ed475b65b6245',
  measurementId: 'G-F75RELS2WD'
}
firebase.initializeApp(firebaseConfig)
Vue.use(GoogleAuth, gauthOption)
Vue.config.productionTip = false

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
